/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, Typography } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import ProgressCard from "../ProgressCard/ProgressCard";
import { DiscountRanges } from "../../utils/types";
import { useSelector } from "react-redux";
import { Locale, SelectedBrand } from "../../utils/redux";
import {
  FilteredApiData,
  OrderData,
  skuDataInterface,
} from "../../utils/redux/reducer/order-slice";

interface OfferGadgetProps {
  discountRanges: DiscountRanges[];
  isSKUOffer: boolean;
  skuData?: skuDataInterface;
  offinvoiceDiscount: DiscountRanges[];
  volumeRanges: DiscountRanges[];
  payTerms: DiscountRanges[];
  isCumulative: boolean;
}

const OfferGadget: FC<OfferGadgetProps> = ({
  discountRanges,
  isSKUOffer,
  skuData,
  offinvoiceDiscount,
  volumeRanges,
  payTerms,
  isCumulative
}) => {
  const brandData = useSelector(SelectedBrand);
  const orderData = useSelector(OrderData);
  const [selected, setSelected] = useState(0);
  const locale = useSelector(Locale);
  const filteredData = useSelector(FilteredApiData);

  useEffect(() => {
    if (orderData.length > 0) {
      let totalUnits = 0;
      for (let closeOrder of filteredData.closeOrders) {
        if (closeOrder.currentPeriod) {
          for (let lineItem of closeOrder.orderLineItems) {
            if (skuData) {
              if (lineItem.skuIdentifier === skuData.skuIdentifier) {
                totalUnits = totalUnits + lineItem.units;
              }
            } else {
              if (lineItem.brandIdentifier === brandData.brandIdentifier) {
                totalUnits = totalUnits + lineItem.units;
              }
            }
          }
        }
      }
      for (let orderItem of orderData) {
        if (skuData) {
          const skuOrderData = orderItem.skuData.filter(
            (skuItem) => skuItem.skuIdentifier === skuData?.skuIdentifier
          );
          for (let skuOrderItem of skuOrderData) {
            totalUnits = totalUnits + skuOrderItem.requestedVolume;
          }
        } else if (orderItem.brandIdentifier === brandData.brandIdentifier) {
          for (let skuOrderItem of orderItem.skuData) {
            totalUnits = skuOrderItem.requestedVolume + totalUnits;
          }
        }
      }
      setSelected(totalUnits);
    } else {
      setSelected(0);
    }
  }, [orderData]);

  const threasHoldCheck = (label: number, nextLabel: number, max: number, secondLastLabel: number) =>{
    const purchased= !isSKUOffer ? brandData.closedPurchased : skuData?.closedPurchased || 0;
    if(selected===max && label===secondLastLabel){
      return true
    }else if(selected!==0 && selected>=label && selected<nextLabel){
      return true;
    }if(selected===0 && purchased!==0 && purchased>=label && purchased<nextLabel){
      return true;
    }else{
      return false;
    }
  }

  const getPercentWithThreashold = (
    discount: DiscountRanges,
    rangesData: DiscountRanges[],
    index: number,
    discountType: string
  ) => {
    return <Typography
      sx={{
        color: !isCumulative ? "black" : threasHoldCheck(
          discount.label,
          rangesData[index + 1].label,
          rangesData[rangesData.length - 1].label,
          rangesData.length > 2
            ? rangesData[rangesData.length - 2].label
            : rangesData[rangesData.length - 1].label
        )
          ? "primary.main"
          : "black",
        fontSize: !isCumulative ? "black" : threasHoldCheck(
          discount.label,
          rangesData[index + 1].label,
          rangesData[rangesData.length - 1].label,
          rangesData.length > 2
            ? rangesData[rangesData.length - 2].label
            : rangesData[rangesData.length - 1].label
        )
          ? "14px"
          : "12px",
      }}
    >
      {rangesData[index + 1].discount}
      {discountType !== "free_goods" && "%"}
    </Typography>;
  };

  return (
    <Box>
      {offinvoiceDiscount.length > 0 && (
        <Grid container>
          {offinvoiceDiscount
            ?.slice(0, offinvoiceDiscount.length - 1)
            ?.map((discount, index: number) => {
              return (
                <Grid
                  xs
                  item
                  key={discount.label}
                  className={`flex ai-c ${index === 0 ? "jc-sb" : "jc-fe"}`}
                >
                  {index === 0 && (
                    <Typography color={"primary"} fontSize={10} noWrap>
                      {brandData.offInvoiceDiscountType?.toLowerCase() ===
                      "free_goods"
                        ? locale?.general?.common?.labels?.freeGoods
                        : locale?.general?.common?.labels?.discount}
                    </Typography>
                  )}
                  {getPercentWithThreashold(discount,offinvoiceDiscount,index,brandData.offInvoiceDiscountType?.toLowerCase())}
                </Grid>
              );
            })}
          <Grid item xs className="flex ai-c jc-fe tar">
            <Typography color={"primary"} fontSize={10} noWrap>
              {locale?.general?.common?.labels?.offInvoice}
            </Typography>
          </Grid>
          <Grid item width={"20px"} />
        </Grid>
      )}
      {discountRanges.length > 0 && (
        <Grid container>
          {discountRanges
            ?.slice(0, discountRanges.length - 1)
            ?.map((discount, index: number) => {
              return (
                <Grid
                  xs
                  item
                  key={discount.label}
                  className={`flex ai-c ${index === 0 ? "jc-sb" : "jc-fe"}`}
                >
                  {index === 0 && (
                    <Typography color={"primary"} fontSize={10} noWrap>
                      {brandData.onInvoiceDiscountType?.toLowerCase() ===
                      "free_goods"
                        ? locale?.general?.common?.labels?.freeGoods
                        : locale?.general?.common?.labels?.discount}
                    </Typography>
                  )}
                  {getPercentWithThreashold(discount,discountRanges,index,brandData.onInvoiceDiscountType?.toLowerCase())}
                </Grid>
              );
            })}
          <Grid item xs className="flex ai-c jc-fe tar">
            <Typography color={"primary"} fontSize={10} noWrap>
              {locale?.general?.common?.labels?.onInvoice}
            </Typography>
          </Grid>
          <Grid item width={"20px"} />
        </Grid>
      )}
      <ProgressCard
        purchased={
          !isCumulative ? 0 :
          !isSKUOffer
            ? brandData.closedPurchased
            : skuData?.closedPurchased || 0
        }
        selected={isCumulative ? selected : 0}
        discountRange={volumeRanges}
        brand={brandData}
      />
      <Grid container>
        {volumeRanges
          ?.slice(0, volumeRanges.length - 1)
          ?.map((discount, index: number) => {
            return (
              <Grid
                xs
                item
                key={discount.label}
                className={`flex ai-c ${index === 0 ? "jc-sb" : "jc-fe"}`}
              >
                {index === 0 && (
                  <Typography color={"primary"} fontSize={11}>
                    {locale?.general?.common?.labels?.value}
                  </Typography>
                )}
                <Typography>{discount.label}</Typography>
              </Grid>
            );
          })}
        <Grid item xs className="tar">
          <Typography>{brandData.actualMaxVolume}</Typography>
        </Grid>
        <Grid item width={"20px"} />
      </Grid>
      {payTerms.length > 0 && (
        <Grid container>
          {payTerms
            ?.slice(0, payTerms.length - 1)
            ?.map((discount, index: number) => {
              return (
                <Grid
                  xs
                  item
                  key={discount.label}
                  className={`flex ai-c ${index === 0 ? "jc-sb" : "jc-fe"}`}
                >
                  {index === 0 && (
                    <Typography color={"primary"} fontSize={11} noWrap>
                      {locale?.general?.common?.labels?.payTerms}
                    </Typography>
                  )}
                  <Typography fontSize={12}>{discount.discount}D</Typography>
                </Grid>
              );
            })}
          <Grid item xs className="tar">
          <Typography fontSize={12}>{payTerms[payTerms.length - 1]?.discount}D</Typography>
          </Grid>
          <Grid item width={"20px"} />
        </Grid>
      )}
    </Box>
  );
};

export default OfferGadget;
