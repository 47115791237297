import React, { Dispatch, FC, SetStateAction, useState } from "react";
import CustomAccordian from "../../../components/CustomAccordian/CustomAccordian";
import { Box, IconButton, TextField, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import {
  CurrentScreen,
  FilteredApiData,
  Locale,
  setCurrentScreen,
  WabaToken,
} from "../../../utils/redux";
import { FilteredCart, skuDataWithBrand } from "../../../utils/types";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { themeColors } from "../../../utils/constants/themeColors";
import DatePicker from "react-datepicker";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {
  checkOfferRangeTypeUnit,
  deleteOrderFromEditOrderFlow,
  disabledDiscreteTextField,
  getBrandData,
  getDraftData,
  getTriad,
  saveCheckoutDraft,
} from "../../../utils/helperFunctions/helperFunction";
import DialogBox from "../../../components/DialogBox/DialogBox";
import {
  AccountsWithTriad,
  setSelectedBrand,
} from "../../../utils/redux/reducer/order-slice";
import { MaxVolumeData } from "./Checkout";
import { offerTypes } from "../../../utils/constants/constatns";
import { screenNames } from "../../../utils/constants/screenNames";
import { sendEvent } from "../../../utils/helperFunctions/sendEvent";
import { eventNames } from "../../../utils/constants/events";
import axiosInstance from "../../../utils/axios";
import { URLS } from "../../../utils/constants/urls";
import EditIcon from "../../../assets/svg/edit-icon";
import CustomButton from "../../../components/CustomButton/CustomButton";

interface CheckoutOrderCardProps {
  filterdCartItem: FilteredCart;
  disableDates: { triad: string; dateToExclude: string[] }[];
  setFilterdCart: Dispatch<SetStateAction<FilteredCart[]>>;
  filterdCart: FilteredCart[];
  indexValue: number;
  maxVolumeData: MaxVolumeData[];
  getDiscount: (
    sku: skuDataWithBrand,
    totalSkuData: FilteredCart,
    type: "onInvoice" | "offInvoice" | "payTerms"
  ) => number;
}

const CheckoutOrderCard: FC<CheckoutOrderCardProps> = ({
  filterdCartItem,
  disableDates,
  filterdCart,
  setFilterdCart,
  indexValue,
  maxVolumeData,
  getDiscount,
}) => {
  const locale = useSelector(Locale);
  const [open, setOpen] = useState(false);
  const filtredData = useSelector(FilteredApiData);
  const [showWholesalerChange, setShowWholesalerChange] = useState(false);
  const dispatch = useDispatch();
  const wabaToken = useSelector(WabaToken);
  const [orderToDelete, setOrderToDelete] = useState<number>();
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const currentScreen = useSelector(CurrentScreen);

  const getOrderNumberBySku = () => {
    const sortedSku = filterdCartItem.skuData
      .filter((item) => item.orderId != null && item.orderId > 0) // Remove invalid orderIds
      .sort((a: any, b: any) => a.orderId - b.orderId);
    if (sortedSku && sortedSku.length > 0) {
      return (
        <Box className="card-order-id-box">
          <Typography className="flex g5 ai-c">
            <Typography color={themeColors.primaryType1} component="span">
              {locale?.general?.common?.labels?.orderNo}
            </Typography>
            {sortedSku[0].orderId}
          </Typography>
          <Typography className="flex g5" mr={"10px"}>
            <Typography color={themeColors.primaryType1} component="span">
              {locale?.general?.common?.labels?.orderStatus}:
            </Typography>
            {sortedSku[0].externalOrderStatus}
          </Typography>
        </Box>
      );
    } else {
      return "";
    }
  };

  const checkValidDates = (orderDate: string, retrunNull: boolean) => {
    const today = dayjs().startOf("day");
    if (orderDate === "" || dayjs(orderDate).startOf("day").isBefore(today)) {
      return retrunNull ? (null as any) : "";
    } else {
      return retrunNull
        ? new Date(dayjs(filterdCartItem.orderDate).format("YYYY-MM-DD"))
        : dayjs(filterdCartItem.orderDate).format("MMM DD, YY");
    }
  };

  const getDateToDisable = () => {
    if (disableDates.length > 0) {
      const findDisableDates = disableDates.find(
        (data) => data.triad === getTriad(filterdCartItem)
      );
      if (findDisableDates) {
        const updatedDataInDateFormat = findDisableDates.dateToExclude
          .filter((date) => date !== filterdCartItem.orderDate)
          .map((date) => new Date(date));
        return updatedDataInDateFormat;
      } else {
        return [];
      }
    } else {
      return [];
    }
  };

  const handleDeleteCard = async () => {
    try {
      if (filterdCartItem.orderId) {
        if(wabaToken){
          setOrderToDelete(filterdCartItem.orderId);
          setDeleteModal(true);
        }else{
          sendEvent(eventNames.deleteOrder, filterdCartItem);
        }
      } else {
        const uniqueId: number[] = [];
        for (let filterSkuItem of filterdCartItem.skuData) {
          if (filterSkuItem.uniqueId) {
            uniqueId.push(filterSkuItem.uniqueId);
          }
        }
        if (uniqueId.length > 0) {
          await axiosInstance.delete(URLS.deleteDrafts, {
            data: uniqueId,
          });
          await getBrandData();
          await getDraftData();
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getAvailableDateForWholesaler = (
    date: string,
    existingOrder: FilteredCart[],
    account: AccountsWithTriad
  ) => {
    const isExist = existingOrder.find((order) => order.orderDate === date);
    let isExistInDisableDates = false;
    for (let datesWithTriad of disableDates) {
      if (datesWithTriad.triad === account.triad) {
        const findDate = datesWithTriad.dateToExclude.find(
          (dateValue) => dateValue === date
        );
        if (findDate) {
          isExistInDisableDates = true;
        }
        break;
      }
    }
    if (isExist || isExistInDisableDates) {
      if (date === filtredData.endDate) {
        alert(locale?.general?.ordering?.snackbars?.noMoreDate);
      } else {
        let nextDate = dayjs(date).add(1, "day").format("YYYY-MM-DD");
        getAvailableDateForWholesaler(nextDate, existingOrder, account);
      }
    } else {
      setOpen(true);
      changeWholesaler(account, date);
    }
  };

  const changeWholesaler = (account: AccountsWithTriad, date: string) => {
    const updatedFilteredCard = filterdCart.map((filterItem, index) => {
      if (indexValue === index) {
        return {
          ...filterItem,
          accountCode: account.accountCode,
          wholesalerName: account.wholesalerName,
          wholesalerCode: account.wholesalerCode,
          legalIdCode: account.legalIdCode,
          orderDate: date,
          isDraft: true,
        };
      } else {
        return filterItem;
      }
    });

    saveCheckoutDraft(updatedFilteredCard, () => {
      setFilterdCart(updatedFilteredCard);
    });
  };

  const handleWholesalerChange = (account: AccountsWithTriad) => {
    const existingOrder = filterdCart.filter(
      (orderItem) => getTriad(orderItem) === account.triad
    );
    if (existingOrder.length > 0) {
      const sameDateOrderExist = existingOrder.find(
        (orderItem) => orderItem.orderDate === filterdCartItem.orderDate
      );
      if (sameDateOrderExist) {
        getAvailableDateForWholesaler(
          dayjs().format("YYYY-MM-DD"),
          existingOrder,
          account
        );
      } else {
        changeWholesaler(account, filterdCartItem.orderDate);
      }
    } else {
      changeWholesaler(account, filterdCartItem.orderDate);
    }
    setShowWholesalerChange(false);
  };

  const handleDateChange = (date: Date) => {
    const updatedFilterCard = filterdCart.map((filterItem, index) => {
      if (index === indexValue) {
        return {
          ...filterItem,
          orderDate: dayjs(date).format("YYYY-MM-DD"),
          isDraft: true,
        };
      } else {
        return filterItem;
      }
    });
    saveCheckoutDraft(updatedFilterCard, () => {
      setFilterdCart(updatedFilterCard);
    });
    setOpen(false);
  };

  const updateQuantity = (
    sku: skuDataWithBrand,
    operation: string,
    value: number
  ) => {
    const updatedFilterCart = filterdCart.map((filterItem, index) => {
      if (index === indexValue) {
        const updatedSkuData = filterItem.skuData.map((skuItem) => {
          if (skuItem.skuIdentifier === sku.skuIdentifier) {
            return {
              ...skuItem,
              requestedVolume:
                operation === "plus"
                  ? skuItem.requestedVolume + 1
                  : operation === "minus"
                  ? skuItem.requestedVolume - 1
                  : value,
            };
          } else {
            return skuItem;
          }
        });
        return { ...filterItem, skuData: updatedSkuData, isDraft: true };
      } else {
        return filterItem;
      }
    });
    saveCheckoutDraft(updatedFilterCart, () => {
      setFilterdCart(updatedFilterCart);
    });
  };

  const handleMaxVolume = (value: number, sku: skuDataWithBrand) => {
    const brand = filtredData.offerData.find(
      (elm) => elm.brandIdentifier === sku.brandIdentifier
    );
    if(brand){
      if(brand.isCumulative){
        const findBrandUnits = maxVolumeData.find(
          (item) => item.brandIdentifier === sku.brandIdentifier
        );    
        if (findBrandUnits) {
          if (findBrandUnits.offerLevel.toLowerCase() === offerTypes.brandLevel) {
            const prevSelected = findBrandUnits.totalSelected - sku.requestedVolume;
            if (prevSelected + value > findBrandUnits.maxVolume) {
              return true;
            } else {
              return false;
            }
          } else {
            const findUnitUnit = findBrandUnits.skuUnits.find(
              (item) => item.skuIdentifier === sku.skuIdentifier
            );
            if (findUnitUnit) {
              const prevSelected = findUnitUnit.selected - sku.requestedVolume;
              if (prevSelected + value > findUnitUnit.maxVolume) {
                return true;
              } else {
                return false;
              }
            } else {
              return true;
            }
          }
        } else {
          return true;
        }
      }else{
        if (brand.offerLevel?.toLowerCase() === offerTypes.skuLevel) {
          const findSkuItem = brand.skuData.find(
            (elm) => elm.skuIdentifier === sku.skuIdentifier
          );
          if (findSkuItem && value > findSkuItem.actualMaxVolume) {
            return true;
          } else {
            return false;
          }
        } else {
          const totalRequestedVolume =
            filterdCartItem.skuData.reduce((acc, item) => {
              return item.brandIdentifier === brand.brandIdentifier &&
                item.skuIdentifier !== sku.skuIdentifier
                ? acc + item.requestedVolume
                : acc;
            }, 0) + value;
          if (totalRequestedVolume > brand.actualMaxVolume) {
            return true;
          } else {
            return false;
          }
        }
      }
    }else{
      return true;
    }    
  };

  const handleQunatity = (sku: skuDataWithBrand, operation: string) => {
    if (operation === "minus" && sku.requestedVolume === 0) {
      return;
    }

    let isMaxError = false;
    if (operation === "plus") {
      isMaxError = handleMaxVolume(sku.requestedVolume + 1, sku);
    }
    if (isMaxError) {
      alert(locale?.general?.ordering?.snackbars?.exceedMaxLimit);
    } else {
      updateQuantity(sku, operation, 0);
    }
  };

  const handleInputQuantity = (inputValue: number, sku: skuDataWithBrand) => {
    if (isNaN(inputValue)) {
      inputValue = 0;
    }
    if (inputValue < 0) {
      return;
    }

    let isMaxError = handleMaxVolume(inputValue, sku);
    if (isMaxError) {
      alert(locale?.general?.ordering?.snackbars?.exceedMaxLimit);
    } else {
      updateQuantity(sku, "inputValue", inputValue);
    }
  };

  const handleBrandSelection = async (brandIdentifier: string) => {
    await getDraftData();
    const findBrand = filtredData.offerData.find(
      (brandItem) => brandItem.brandIdentifier === brandIdentifier
    );
    if (findBrand) {
      dispatch(setSelectedBrand(findBrand));
      dispatch(setCurrentScreen(screenNames.order));
      sendEvent(eventNames.brandSelected, findBrand);
    }
  };

  const showDiscount = (
    skuItem: skuDataWithBrand,
    field: "onInvoice" | "offInvoice"
  ) => {
    if(skuItem.requestedVolume<1){
      return "-";
    }else{
      let discount;
    const brand = filtredData.offerData.find(
      (elm) => elm.brandIdentifier === skuItem.brandIdentifier
    );
    if (brand) {
      if (field === "onInvoice") {
        if (brand.onInvoiceDiscountRanges.length === 0) {
          return "-";
        } else {
          discount = getDiscount(skuItem, filterdCartItem, "onInvoice");
          if (brand.onInvoiceDiscountType?.toLowerCase() === "free_goods") {
            return <span>{discount} <span>{locale?.general?.common?.labels?.freeGoodsIndicator}</span></span>;
          } else {
            return `${discount}%`;
          }
        }
      } else {
        if (brand.offInvoiceDiscountRanges.length === 0) {
          return "-";
        } else {
          discount = getDiscount(skuItem, filterdCartItem, "offInvoice");
          if (brand.offInvoiceDiscountType?.toLowerCase() === "free_goods") {
            return <span>{discount} <span >{locale?.general?.common?.labels?.freeGoodsIndicator}</span></span>;
          } else {
            return `${discount}%`;
          }
        }
      }
    }
    return 0;
    }
  };

  return (
    <>
      <CustomAccordian
        isOrderDraft
        showEdit
        setShowAccountList={setShowWholesalerChange}
        padding={(filterdCartItem.orderId || wabaToken) ? "10px 10px 16px 10px" : undefined}
        customHeight={wabaToken && filterdCartItem.orderId ? 100 : wabaToken ? 60 : filterdCartItem.orderId ? 65 : 38}
        expandedHeight={wabaToken && filterdCartItem.orderId ? 100 : wabaToken ? 60 : filterdCartItem.orderId ? 65 : 38}
        sx={{ paddingTop: 0, paddingLeft: "23px" }}
        defaultOpen={true}
        title={
          <Box className="fullWidth">
            <Box className="card-child">
              <Box className="flex g15 jc-sb ai-c">
                <Typography fontSize={14}>
                  {filterdCartItem.wholesalerName} -{" "}
                  {filterdCartItem.accountCode}
                </Typography>
                {wabaToken && <IconButton onClick={(event)=>{
                  event.stopPropagation();
                  handleDeleteCard();
                  }} sx={{ padding: 0}}>
                  <DeleteOutlineIcon color="primary" />
                </IconButton>}
              </Box>
              <Box
                className="flex ai-c pointer open-order-date-picker"
                onClick={(event) => {
                  event.stopPropagation();
                }}
              >
                <CalendarMonthIcon
                  sx={{ fontSize: 16 }}
                  onClick={(event) => {
                    setOpen(true);
                  }}
                />
                <DatePicker
                  placeholderText={locale?.general?.order?.labels?.selectDate}
                  excludeDates={getDateToDisable()}
                  onInputClick={() => {
                    setOpen(true);
                  }}
                  popperPlacement="bottom-start"
                  readOnly={true}
                  open={open}
                  onClickOutside={() => {
                    setOpen(false);
                  }}
                  autoComplete="off"
                  className="card-date-picker"
                  dateFormat="MMM dd, yy"
                  selected={checkValidDates(filterdCartItem.orderDate, true)}
                  value={checkValidDates(filterdCartItem.orderDate, false)}
                  onChange={(date) => {
                    if (date) {
                      handleDateChange(date);
                    }
                  }}
                  minDate={new Date(new Date().toISOString().split("T")[0])}
                  maxDate={
                    !!filtredData.endDate
                      ? new Date(filtredData.endDate)
                      : new Date(new Date().toISOString().split("T")[0])
                  }
                />
                {!wabaToken && <IconButton onClick={handleDeleteCard}>
                  <DeleteOutlineIcon color="primary" />
                </IconButton>}
              </Box>
            </Box>
            {getOrderNumberBySku()}
          </Box>
        }
      >
        <Box className="flex" textAlign={"center"}>
          <Typography color={"primary"} flex={1.5} textAlign={"left"}>
            {locale?.general?.common?.labels?.sku}
          </Typography>
          <Typography color={"primary"} flex={1}>
            {locale?.general?.common?.labels?.units}
          </Typography>
          <Typography color={"primary"} flex={0.7}>
            {locale?.general?.common?.labels?.onInvoice}
          </Typography>
          <Typography color={"primary"} flex={0.7}>
            {locale?.general?.common?.labels?.offInvoice}
          </Typography>
          <Box flex={0.4} />
        </Box>
        <Box>
          {filterdCartItem.skuData.map((skuItem, index) => {
            return (
              <React.Fragment key={index}>
                <Box
                  className="flex ai-c"
                  marginTop={
                    index > 0 &&
                    filterdCartItem.skuData[index - 1].brandIdentifier !==
                      filterdCartItem.skuData[index].brandIdentifier
                      ? "10px"
                      : "5px"
                  }
                  paddingTop={
                    index > 0 &&
                    filterdCartItem.skuData[index - 1].brandIdentifier !==
                      filterdCartItem.skuData[index].brandIdentifier
                      ? "5px"
                      : "0px"
                  }
                  sx={{
                    borderTop:
                      index > 0 &&
                      filterdCartItem.skuData[index - 1].brandIdentifier !==
                        filterdCartItem.skuData[index].brandIdentifier
                        ? "1px solid #c5c1c1"
                        : "0px",
                  }}
                >
                  <Typography flex={1.5}>{skuItem.sku}</Typography>
                  <Box className="flex f1 us-remove">
                    <Box
                      className="quantity-handle-button minus-button flex ai-c jc-c"
                      bgcolor={themeColors.primaryType1}
                      onClick={() => {
                        const brandData = filtredData.offerData.find(
                          (elm) =>
                            elm.brandIdentifier === skuItem.brandIdentifier
                        );
                        if (brandData) {
                          if (
                            brandData.offerLevel?.toLowerCase() ===
                              offerTypes.brandLevel ||
                            brandData.isCumulative
                          ) {
                            handleQunatity(skuItem, "minus");
                          } else {
                            const findSku = brandData.skuData.find(
                              (elm) =>
                                elm.skuIdentifier === skuItem.skuIdentifier
                            );
                            if (
                              findSku?.offerRangeType?.toLowerCase() ===
                              "discrete"
                            ) {
                              const unitToReplace = checkOfferRangeTypeUnit(
                                "MINUS",
                                skuItem.requestedVolume,
                                findSku
                              );
                              handleInputQuantity(unitToReplace, skuItem);
                            } else {
                              handleQunatity(skuItem, "minus");
                            }
                          }
                        } else {
                          handleQunatity(skuItem, "minus");
                        }
                      }}
                    >
                      <Typography fontWeight={500}>-</Typography>
                    </Box>
                    <Box
                      className="selected-quantity flex ai-c jc-c"
                      bgcolor={themeColors.secondaryType1}
                    >
                      <TextField
                        disabled={disabledDiscreteTextField(
                          skuItem.brandIdentifier,
                          skuItem.skuIdentifier
                        )}
                        fullWidth={false}
                        sx={{
                          width: "75px",
                          "& .MuiOutlinedInput-root": {
                            "& input": {
                              padding: 0,
                              textAlign: "center",
                              color: "black !important",
                              "-webkit-text-fill-color": "black !important",
                              opacity: 1,
                            },
                            padding: "0px",
                            "& fieldset": {
                              border: "none",
                            },
                          },
                          "& .MuiInputBase-root.Mui-disabled": {
                            color: "black !important",
                            "-webkit-text-fill-color": "black !important",
                            opacity: 1,
                          },
                        }}
                        value={skuItem.requestedVolume}
                        variant="outlined"
                        onChange={(e) => {
                          handleInputQuantity(
                            parseInt(e.target.value),
                            skuItem
                          );
                        }}
                      />
                    </Box>
                    <Box
                      className="quantity-handle-button plus-button flex ai-c jc-c"
                      bgcolor={themeColors.primaryType1}
                      onClick={() => {
                        const brandData = filtredData.offerData.find(
                          (elm) =>
                            elm.brandIdentifier === skuItem.brandIdentifier
                        );
                        if (brandData) {
                          if (
                            brandData.offerLevel?.toLowerCase() ===
                              offerTypes.brandLevel ||
                            brandData.isCumulative
                          ) {
                            handleQunatity(skuItem, "plus");
                          } else {
                            const findSku = brandData.skuData.find(
                              (elm) =>
                                elm.skuIdentifier === skuItem.skuIdentifier
                            );
                            if (
                              findSku?.offerRangeType?.toLowerCase() ===
                              "discrete"
                            ) {
                              const unitToReplace = checkOfferRangeTypeUnit(
                                "PLUS",
                                skuItem.requestedVolume,
                                findSku
                              );
                              handleInputQuantity(unitToReplace, skuItem);
                            } else {
                              handleQunatity(skuItem, "plus");
                            }
                          }
                        } else {
                          handleQunatity(skuItem, "plus");
                        }
                      }}
                    >
                      <Typography fontWeight={500}>+</Typography>
                    </Box>
                  </Box>
                  <Typography flex={0.7} textAlign={"center"}>
                    {showDiscount(skuItem, "onInvoice")}
                  </Typography>
                  <Typography flex={0.7} textAlign={"center"}>
                    {showDiscount(skuItem, "offInvoice")}
                  </Typography>
                  <Box flex={0.4}>
                    {(index === 0 ||
                      (index > 0 &&
                        filterdCartItem.skuData[index - 1].brandIdentifier !==
                          filterdCartItem.skuData[index].brandIdentifier)) && (
                      <IconButton
                        onClick={() => {
                          handleBrandSelection(skuItem.brandIdentifier);
                        }}
                      >
                        <EditIcon sx={{ fontSize: 16 }} />
                      </IconButton>
                    )}
                  </Box>
                </Box>
              </React.Fragment>
            );
          })}
        </Box>
      </CustomAccordian>
      <DialogBox open={showWholesalerChange} setOpen={setShowWholesalerChange}>
        <Box className="p10 accountL-list-container">
          {filtredData.accounts.length === 1 ? (
            <Box
              onClick={() => {
                setShowWholesalerChange(false);
              }}
              className="account-list-item"
            >
              <Typography fontSize={14}>
                {filtredData.accounts[0].wholesalerName} -{" "}
                {filtredData.accounts[0].accountCode}
              </Typography>
            </Box>
          ) : (
            filtredData.accounts.map((account) => {
              return (
                account.triad !== getTriad(filterdCartItem) && (
                  <Box
                    onClick={() => {
                      handleWholesalerChange(account);
                    }}
                    key={account.triad}
                    className="account-list-item"
                  >
                    <Typography fontSize={14}>
                      {account.wholesalerName} - {account.accountCode}
                    </Typography>
                  </Box>
                )
              );
            })
          )}
        </Box>
      </DialogBox>
      <DialogBox open={deleteModal} setOpen={setDeleteModal}>
        <Box className="p15">
          <Typography>{locale?.general?.skuOrdering?.skuOrdering?.deleteOrderMessage}</Typography>
          <Box className="flex jc-e mt10">
          <CustomButton width="70px" align="center" onClick={()=>{
            deleteOrderFromEditOrderFlow(orderToDelete,currentScreen);
          }}>
          <Typography>
            {locale?.general?.common?.buttons?.yes}
            </Typography>
            </CustomButton>
          <CustomButton 
          background="#e6e6e6"
          color="#959595"
          hoverBackground="#d2cece" width="70px" align="center" onClick={()=>{
            setOrderToDelete(undefined);
            setDeleteModal(false);
          }}>
            <Typography>
            {locale?.general?.common?.buttons?.no}
            </Typography></CustomButton>
        </Box>
        </Box>
      </DialogBox>
    </>
  );
};

export default CheckoutOrderCard;
