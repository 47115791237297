/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomAccordian from "../../../components/CustomAccordian/CustomAccordian";
import "./index.css";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useDispatch, useSelector } from "react-redux";
import { Loc, Locale, setLoading, User } from "../../../utils/redux";
import axiosInstance from "../../../utils/axios";
import { URLS } from "../../../utils/constants/urls";
import { RefreshProfile, RepData } from "../../../utils/redux/reducer/authentication-slice";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import PendingIcon from "@mui/icons-material/Pending";
import ImageIcon from "@mui/icons-material/Image";
import CustomButton from "../../../components/CustomButton/CustomButton";
import { themeColors } from "../../../utils/constants/themeColors";
import ToggleButton from "../../../components/ToggleButton/ToggleButton";
import { sendEvent } from "../../../utils/helperFunctions/sendEvent";
import { eventNames } from "../../../utils/constants/events";
import { UserProfileInterface } from "../../../utils/types";

const StatusRibbon = ({ status }: { status: string }) => {
  return (
    <Box
      bgcolor={
        status === "VERIFIED"
          ? "success.main"
          : status === "PENDING_VERIFICATION"
          ? "warning.light"
          : "error.main"
      }
      height={"17px"}
      width={"5px"}
      borderRadius={"5px"}
    />
  );
};

const UserProfile = () => {
  const dispatch = useDispatch();
  const user = useSelector(User);
  const loc = useSelector(Loc);
  const locale = useSelector(Locale);
  const repData = useSelector(RepData);
  const refreshProfile = useSelector(RefreshProfile);
  const [profileData, setProfileData] = useState<UserProfileInterface>({
    profileDetails: {
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      otherInformation: false,
      marketingCampaigns: false,
      transactional: false,
      sms: false,
      electronicMarketingCampaigns: false,
      instantMessageConsent: false,
    },
    associatedAccounts: [
      {
        wholeSalerCode: "",
        wholeSalerName: "",
        wholeSalerStatus: "",
        accounts: [
          {
            accountNumber: "",
            legalIdCode: "",
            invoice: "",
            accountStatus: "",
          },
        ],
      },
    ],
    notification: false,
  });

  const StatusMark = ({ status }: { status: string }) => {
    return (
      <Box width={"45px"} height={"20px"}>
        {status === "VERIFIED" ? (
          <CheckCircleIcon
            className="pointer"
            sx={{ color: "success.main" }}
            data-tooltip-id="custom-tooltip"
            data-tooltip-content={locale?.general?.profile?.tooltip?.verified}
          />
        ) : status === "PENDING_VERIFICATION" ? (
          <PendingIcon
            className="pointer"
            sx={{ color: "warning.light" }}
            data-tooltip-id="custom-tooltip"
            data-tooltip-content={locale?.general?.profile?.tooltip?.pendingVerification}
          />
        ) : (
          <CancelIcon
            className="pointer"
            sx={{ color: "error.main" }}
            data-tooltip-id="custom-tooltip"
            data-tooltip-content={locale?.general?.profile?.tooltip?.unverified}
          />
        )}
      </Box>
    );
  };

  const getProfileData = async () => {
    try {
      dispatch(setLoading(true));
      const { data } = await axiosInstance.get(
        URLS.getProfileWithPreferences(user.phone || repData.phone, loc)
      );
      dispatch(setLoading(false));
      setProfileData(data);
    } catch (error) {
      dispatch(setLoading(false));
      console.log(error);
    }
  };

  useEffect(() => {
    (async () => {
      await getProfileData();
    })();
  }, [refreshProfile]);

  const updateUserPreferences = async (
    keyName: string,
    updatedValue: boolean
  ) => {
    try {
      dispatch(setLoading(true));
      const body = {
        otherInformation: profileData.profileDetails.otherInformation,
        marketingCampaigns: profileData.profileDetails.marketingCampaigns,
        transactional: profileData.profileDetails.transactional,
        sms: profileData.profileDetails.sms,
        electronicMarketingCampaigns:
          profileData.profileDetails.electronicMarketingCampaigns,
        instantMessageConsent: profileData.profileDetails.instantMessageConsent,
      };
      await axiosInstance.put(
        URLS.updateUserPreferences(user.phone || repData.phone, loc),
        { ...body, [keyName]: updatedValue }
      );
      dispatch(setLoading(false));
      await getProfileData();
    } catch (error) {
      dispatch(setLoading(false));
      console.log(error);
    }
  };

  return (
    <Box className="p20">
      <CustomAccordian
        defaultOpen={true}
        title={
          <Typography
            color={"primary"}
            fontWeight={500}
            fontSize={{ lg: 15, xl: 18 }}
          >
            {locale?.general?.profile?.labels?.profileDetails}
          </Typography>
        }
      >
        <Box>
          <Box className="flex">
            <Box flex={1}>
              <Box className="flex g5">
                <Typography
                  className="profile-detail-title"
                  color="secondary.main"
                >
                  {locale?.general?.profile?.labels?.firstName}:
                </Typography>
                <Typography className="profile-detail-text">
                  {profileData.profileDetails.firstName}
                </Typography>
              </Box>
              <Box className="flex g5">
                <Typography
                  className="profile-detail-title"
                  color="secondary.main"
                >
                  {locale?.general?.profile?.labels?.mobileNumber}:
                </Typography>
                <Typography className="profile-detail-text">
                  {profileData.profileDetails.phoneNumber}
                </Typography>
              </Box>
            </Box>
            <Box flex={1}>
              <Box className="flex g5">
                <Typography
                  className="profile-detail-title"
                  color="secondary.main"
                >
                  {locale?.general?.profile?.labels?.lastName}:
                </Typography>
                <Typography className="profile-detail-text">
                  {profileData.profileDetails.lastName}
                </Typography>
              </Box>

              <Box className="flex g5">
                <Typography
                  className="profile-detail-title"
                  color="secondary.main"
                >
                  {locale?.general?.profile?.labels?.email}:
                </Typography>
                <Typography className="profile-detail-text">
                  {profileData.profileDetails.email}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </CustomAccordian>

      <Box
        bgcolor={"white"}
        className="br5"
        boxShadow={"0px 3px 12px rgba(84, 79, 64, 0.1)"}
        p={"10px 18px"}
      >
        <Typography
          color={"primary"}
          fontWeight={500}
          fontSize={{ lg: 15, xl: 18 }}
        >
          {locale?.general?.profile?.labels?.associatedAccounts}
        </Typography>
        <Box className="mt10">
          {profileData.associatedAccounts &&
            profileData.associatedAccounts.map((wholesaleAccount) => {
              return (
                <Box
                  key={wholesaleAccount.wholeSalerCode}
                  borderTop={"0.5px solid #e6e6e6"}
                  paddingTop={"3px"}
                >
                  <CustomAccordian
                    padding={"5px"}
                    expandedHeight={20}
                    defaultOpen={true}
                    customHeight={20}
                    title={
                      <Box className="flex ai-c jc-sb fullWidth">
                        <Typography fontSize={{ lg: 14.5, xl: 16 }}>
                          {wholesaleAccount.wholeSalerName}
                        </Typography>
                        <Typography
                          color={
                            wholesaleAccount.wholeSalerStatus === "ACTIVE"
                              ? "success.main"
                              : "error.main"
                          }
                          fontWeight={500}
                          fontSize={{ lg: 12.5, xl: 13.5 }}
                          mr={"10px"}
                        >
                          {wholesaleAccount.wholeSalerStatus === "ACTIVE" ? locale?.general?.profile?.labels?.activeAccount : locale?.general?.profile?.labels?.inactiveAccount}
                        </Typography>
                      </Box>
                    }
                    shadow={false}
                  >
                    <Box className="flex">
                      <Box width={"25px"} />
                      <Box className="flex f1" gap={"3px"}>
                        <Typography
                          color="primary"
                          fontWeight={500}
                          className="f2"
                          fontSize={{ lg: 12, xl: 14 }}
                        >
                          {locale?.general?.profile?.labels?.accountNumber}
                        </Typography>
                        <Typography
                          color="primary"
                          fontWeight={500}
                          className="f1"
                          fontSize={{ lg: 12, xl: 14 }}
                        >
                          {locale?.general?.profile?.labels?.legalId}
                        </Typography>
                        <Typography
                          color="primary"
                          fontWeight={500}
                          width={"45px"}
                          fontSize={{ lg: 12, xl: 14 }}
                        >
                          {locale?.general?.common?.labels?.status}
                        </Typography>
                      </Box>
                      <Box width={"85px"} />
                    </Box>
                    {wholesaleAccount.accounts &&
                      wholesaleAccount.accounts.map((account) => {
                        return (
                          <Box
                            className="flex ai-c mt5 pt5"
                            borderTop={"0.5px solid #e6e6e6"}
                            key={account.accountNumber}
                            pt={"3px"}
                          >
                            <Box width={"25px"}>
                              <StatusRibbon status={account.accountStatus} />
                            </Box>
                            <Box className="flex f1 ai-c" gap={"3px"}>
                              <Box className="f2 flex g5">
                                <Box>
                                  <Typography fontSize={{ lg: 13, xl: 15 }}>
                                    {account.accountNumber}
                                  </Typography>
                                </Box>
                              </Box>
                              <Typography
                                className="f1"
                                fontSize={{ lg: 13, xl: 15 }}
                              >
                                {account.legalIdCode}
                              </Typography>
                              <StatusMark status={account.accountStatus} />
                            </Box>
                            <Box width={"85px"} className="flex jc-c g5">
                              <ImageIcon
                                onClick={() => {
                                  window.open(account.invoice, "_blank");
                                }}
                                color="primary"
                                className="pointer"
                              />
                              <AttachFileIcon
                                color="primary"
                                className="pointer"
                                onClick={() => {
                                  sendEvent(eventNames.attachInvoice, {
                                    accountNumber: account.accountNumber,
                                    accountStatus: account.accountStatus,
                                    invoice: account.invoice,
                                    legalIdCode: account.legalIdCode,
                                    wholeSalerCode:
                                      wholesaleAccount.wholeSalerCode,
                                    wholeSalerName:
                                      wholesaleAccount.wholeSalerName,
                                    wholeSalerStatus:
                                      wholesaleAccount.wholeSalerStatus,
                                    accounts: account,
                                  });
                                }}
                              />
                              <DeleteOutlineIcon
                                color="primary"
                                className="pointer"
                                onClick={() => {
                                  sendEvent(
                                    eventNames.deleteAssociatedAccount,
                                    {
                                      accountNumber: account.accountNumber,
                                      accountStatus: account.accountStatus,
                                      invoice: account.invoice,
                                      legalIdCode: account.legalIdCode,
                                      wholeSalerCode:
                                        wholesaleAccount.wholeSalerCode,
                                      wholeSalerName:
                                        wholesaleAccount.wholeSalerName,
                                      wholeSalerStatus:
                                        wholesaleAccount.wholeSalerStatus,
                                      accounts: account,
                                    }
                                  );
                                }}
                              />
                            </Box>
                          </Box>
                        );
                      })}
                  </CustomAccordian>
                </Box>
              );
            })}

          <Box textAlign={"center"}>
            <CustomButton
              onClick={() => {
                sendEvent(eventNames.addAssociatedaccounts, {});
              }}
              align="center"
              width="105px"
              padding="3px"
            >
              <Typography>{locale?.general?.profile?.buttons?.addAccount}</Typography>
            </CustomButton>
          </Box>
        </Box>
      </Box>

      <Box className="mt10">
        <CustomAccordian
          title={
            <Typography
              color={"primary"}
              fontWeight={500}
              fontSize={{ lg: 15, xl: 18 }}
            >
              {locale?.general?.profile?.labels.userPreference}
            </Typography>
          }
        >
          <Typography color="secondary.main" fontSize={{ lg: 13, xl: 14 }}>
            {locale?.general?.profile?.labels?.contentPreference}
          </Typography>
          <Box className="flex mt5 g10 jc-sb" flexWrap={"wrap"}>
            <Box className="flex ai-c g5 sameline">
              <Typography fontSize={{ lg: 13, xl: 15 }}>
                {locale?.general?.profile?.labels?.offerInformation}
              </Typography>
              <ToggleButton
                flag={profileData.profileDetails.otherInformation}
                keyName="otherInformation"
                handleOnClick={updateUserPreferences}
              />
            </Box>
            <Box className="flex ai-c g5 sameline">
              <Typography fontSize={{ lg: 13, xl: 15 }}>
                {locale?.general?.profile?.labels?.marketingCampaigns}
              </Typography>
              <ToggleButton
                flag={profileData.profileDetails.marketingCampaigns}
                keyName="marketingCampaigns"
                handleOnClick={updateUserPreferences}
              />
            </Box>
            <Box className="flex ai-c g5 sameline">
              <Typography fontSize={{ lg: 13, xl: 15 }}>
                {locale?.general?.profile?.labels?.transactional}
              </Typography>
              <ToggleButton
                flag={profileData.profileDetails.transactional}
                keyName="transactional"
                handleOnClick={updateUserPreferences}
              />
            </Box>
          </Box>
          <Typography
            color="secondary.main"
            className="pt15"
            fontSize={{ lg: 13, xl: 14 }}
          >
            {locale?.general?.profile?.labels?.notificationsChannels}
          </Typography>
          <Box className="flex mt5 sameline g5 jc-sb" flexWrap={"wrap"}>
            <Box className="flex ai-c g10">
              <Typography fontSize={{ lg: 13, xl: 15 }}>{locale?.general?.profile?.labels?.email}</Typography>
              <ToggleButton
                flag={profileData.profileDetails.electronicMarketingCampaigns}
                keyName="electronicMarketingCampaigns"
                handleOnClick={updateUserPreferences}
              />
            </Box>
            <Box className="flex ai-c g10 jc-c">
              <Typography fontSize={{ lg: 13, xl: 15 }}>
                {locale?.general?.profile?.labels?.instantMessaging}
              </Typography>
              <ToggleButton
                flag={profileData.profileDetails.instantMessageConsent}
                keyName="instantMessageConsent"
                handleOnClick={updateUserPreferences}
              />
            </Box>
            <Box className="flex ai-c g10">
              <Typography fontSize={{ lg: 13, xl: 15 }}>{locale?.general?.profile?.labels?.sms}</Typography>
              <ToggleButton
                flag={profileData.profileDetails.sms}
                keyName="sms"
                handleOnClick={updateUserPreferences}
              />
            </Box>
          </Box>
          <Box className="tar mt20">
            <CustomButton
              background={themeColors.secondaryType2}
              hoverBackground={themeColors.secondaryType2}
              onClick={() => {
                sendEvent(eventNames.deleteUser, {});
              }}
              align="center"
              width="85px"
              padding="3px"
            >
              <Typography fontSize={11}>{locale?.general?.profile?.buttons?.unregister}</Typography>
            </CustomButton>
          </Box>
        </CustomAccordian>
      </Box>
    </Box>
  );
};

export default UserProfile;
