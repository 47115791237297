import store from "./store";
import {
  BotEventData,
  CurrentScreen,
  isAuthenticated,
  RepData,
  setBotEventData,
  setCurrentScreen,
  setRepData,
  setWabaToken,
  setUserProfile,
  WabaToken,
  User,
  setRefreshProfile,
  RefreshProfile,
} from "./reducer/authentication-slice";
import { setAuthentication, setUser } from "./reducer/authentication-slice";
import { Loc, Locale, setLoc, setLocale } from "./reducer/loc-slice";
import {
  setFilteredApiData,
  OrderData,
  FilteredApiData,
  setOrderData,
  SelectedBrand,
  setSelectedBrand,
  Cart,
  setCart,
  setIndexValue,
  IndexValue,
  setSelectedEditOrder,
  SelectedEditOrder,
  CheckoutData,
  setCheckoutData,
} from "./reducer/order-slice";
import {
  BannerLoaded,
  isLoading,
  setBannerLoaded,
  setLoading,
} from "./reducer/loading-slice";
import UserProfile from "../../screens/Authenticated/UserProfile/UserProfile";

export default store;

export {
  RefreshProfile,
  setRefreshProfile,
  isAuthenticated,
  User,
  Locale,
  OrderData,
  Loc,
  setAuthentication,
  setUser,
  setLocale,
  setOrderData,
  setLoc,
  CurrentScreen,
  setCurrentScreen,
  setFilteredApiData,
  FilteredApiData,
  isLoading,
  setLoading,
  BannerLoaded,
  setBannerLoaded,
  setUserProfile,
  UserProfile,
  SelectedBrand,
  setSelectedBrand,
  setBotEventData,
  BotEventData,
  setRepData,
  RepData,
  Cart,
  setCart,
  setIndexValue,
  IndexValue,
  setSelectedEditOrder,
  SelectedEditOrder,
  CheckoutData,
  setCheckoutData,
  setWabaToken,
  WabaToken
};