export const offerTypes = {
    brandLevel: 'brand',
    skuLevel: 'sku'
}

export const tncPages = {
    homePage: "homepage",
    termsAndCondition: "termAndCondition",
    footer: "footer"
}

const getTrustedOrigin = () =>{
    const path = window.location.pathname;
    if (path.includes("/chile")) {
        return process.env.REACT_APP_CHILE_TRUSTED_ORIGIN;
    } else if (path.includes("/peru")) {
        return process.env.REACT_APP_PERU_TRUSTED_ORIGIN;
    } else if (path.includes("/ecuador")) {
        return process.env.REACT_APP_ECUADOR_TRUSTED_ORIGIN;
    } else if(path.includes("/spain")){
        return process.env.REACT_APP_SPAIN_TRUSTED_ORIGIN;
    } else if(path.includes("/portugal")){
        return process.env.REACT_APP_PORTUGAL_TRUSTED_ORIGIN;
    } else{
        return process.env.REACT_APP_TRUSTED_ORIGIN;
    }
}

export const trustedOrigin = getTrustedOrigin(); 