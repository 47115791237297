import { Box, Typography } from "@mui/material";
import React, { FC } from "react";
import { sxStyle } from "./sxStyle";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import "./index.css";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import PendingIcon from "@mui/icons-material/Pending";
import LoginIcon from "@mui/icons-material/Login";
import { PharmacyUserResponse } from "../../../utils/types";
import { sendEvent } from "../../../utils/helperFunctions/sendEvent";
import { eventNames } from "../../../utils/constants/events";
import { Locale, setCurrentScreen, setUser } from "../../../utils/redux";
import { useDispatch, useSelector } from "react-redux";
import { screenNames } from "../../../utils/constants/screenNames";
import dayjs from "dayjs";

const CheckedCircle = ({ color = "success.main" }) => {
  return <CheckCircleIcon sx={{ color }} />;
};

const PendingICON = () => {
  return <PendingIcon sx={{ color: "warning.light" }} />;
};

const CrossIcon = () => {
  return <CancelIcon sx={{ color: "error.main" }} />;
};
const contentFontSize = 13;

interface ClientCardProps {
  client: PharmacyUserResponse;
  iconRow: {
    r1: string;
    r2: string;
    r3: string;
    r4: string;
    r5: string;
    r6: string;
    r7: string;
  };
  setSelectedIndex: React.Dispatch<React.SetStateAction<number>>;
  setSelectedCustomerType: React.Dispatch<React.SetStateAction<string>>;
  indexValue: number;
  selectedIndex: number;
  selectedCustomerType: string;
}

const ClientCard: FC<ClientCardProps> = ({
  client,
  iconRow,
  setSelectedIndex,
  indexValue,
  selectedIndex,
  setSelectedCustomerType,
  selectedCustomerType,
}) => {
  const dispatch = useDispatch();
  const locale = useSelector(Locale);
  return (
    <Box className="client-select-acco mb10">
      <Box className="flex ai-c">
        <Box className="flex ai-c g10" sx={{ width: "100%" }}>
          <Box
            bgcolor={sxStyle.getStatusColor(client.active)}
            className="rep-status-mark"
          />
          <Box
            flex={1}
            minWidth={0}
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            <Typography
              color={"primary.main"}
              fontSize={14}
              fontWeight={500}
              noWrap
            >
              {(client.clientCode || client.registrationGroupName) ? <>{client.clientCode} {client.clientCode && client.registrationGroupName && "-"} {client.registrationGroupName}</> : "NA"}
            </Typography>
          </Box>
          <Box className="flex ai-c g5">
            <Box width={iconRow.r1} className="flex ai-c jc-c">
              {client.instantMessageConsent ? (
                <CheckedCircle color="#BCFFC0" />
              ) : (
                <CrossIcon />
              )}
            </Box>
            <Box width={iconRow.r2} className="flex ai-c jc-c">
              {client.accountStatus === "VERIFIED" ? (
                <CheckedCircle color="#BCFFC0" />
              ) : client.accountStatus === "PENDING_VERIFICATION" ? (
                <PendingICON />
              ) : (
                <CrossIcon />
              )}
            </Box>
            <Box width={iconRow.r3} className="flex ai-c jc-c">
              {client.validOfferPresent ? (
                <CheckedCircle color="#BCFFC0" />
              ) : (
                <CrossIcon />
              )}
            </Box>
            <Box width={iconRow.r4} className="flex ai-c jc-c">
              {client.lastSeen ? <CheckedCircle color="#BCFFC0"/> : <CrossIcon />}
            </Box>
            <Box width={iconRow.r5} className="flex ai-c jc-c">
              {client.isOrdered ? <CheckedCircle color="#BCFFC0"/> : <CrossIcon />}
            </Box>
            <Box width={iconRow.r6} className="flex ai-c jc-c">
              <LoginIcon
                onClick={() => {
                  dispatch(
                    setUser({
                      name: client.firstName + " " + client.lastName,
                      email: client.email,
                      phone: client.phoneNumber,
                      user_id: client.user_id,
                    })
                  );
                  dispatch(setCurrentScreen(screenNames.userWelcomeScreen));
                  sendEvent(eventNames.clientSelect, client);
                }}
                className="pointer"
                sx={{ color: "primary.main", mr: "3px" }}
              />
            </Box>
            <Box width={iconRow.r7} className="flex ai-c jc-c">
              {indexValue === selectedIndex &&
              selectedCustomerType === client.customerType ? (
                <KeyboardArrowDownIcon
                  className="pointer"
                  sx={{ fontSize: 23 }}
                  onClick={() => {
                    setSelectedIndex(-1);
                    setSelectedCustomerType("");
                  }}
                />
              ) : (
                <KeyboardArrowUpIcon
                  className="pointer"
                  sx={{ fontSize: 23 }}
                  onClick={() => {
                    setSelectedIndex(indexValue);
                    setSelectedCustomerType(client.customerType);
                  }}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      {indexValue === selectedIndex &&
        selectedCustomerType === client.customerType && (
          <Box className="pl15 pr15 pt5 pb5">
            <Box className="flex">
              <Typography fontSize={contentFontSize}>
                <span className="sec-text">{locale?.general?.representative?.labels?.userId}:</span> {client.user_id}
                <span className="sec-text">&nbsp;&nbsp;|&nbsp;&nbsp;</span>
              </Typography>

              <Typography fontSize={contentFontSize}>
                <span className="sec-text">{locale?.general?.representative?.labels?.firstName}:</span> {client.firstName}{" "}
                <span className="sec-text">&nbsp;&nbsp;|&nbsp;&nbsp;</span>
              </Typography>
              <Typography fontSize={contentFontSize}>
                <span className="sec-text">{locale?.general?.representative?.labels?.lastName}:</span> {client.lastName}
              </Typography>
            </Box>
            <Box className="flex">
              <Typography fontSize={contentFontSize}>
                <span className="sec-text">{locale?.general?.representative?.labels?.mobile}:</span> {client.phoneNumber}
                <span className="sec-text">&nbsp;&nbsp;|&nbsp;&nbsp;</span>
              </Typography>
              <Typography fontSize={contentFontSize}>
                <span className="sec-text">{locale?.general?.representative?.labels?.email}:</span> {client.email}
              </Typography>
            </Box>
            <Box className="flex">
              <Typography fontSize={contentFontSize}>
                <span className="sec-text">{locale?.general?.representative?.labels?.lastConversation}:</span> {client.lastConversation ? dayjs(client.lastConversation).format(
                    "MMM D, YYYY"
                  ) : "NA"}
                <span className="sec-text">&nbsp;&nbsp;|&nbsp;&nbsp;</span>
              </Typography>
              <Typography fontSize={contentFontSize}>
                <span className="sec-text">{locale?.general?.representative?.labels?.lastOrder}:</span>{" "}
                {client.lastOrderCreatedAt ?
                  dayjs(client.lastOrderCreatedAt, "DD-MM-YYYY").format(
                    "MMM D, YYYY"
                  ) : "NA"}
                {/* <span className="sec-text">&nbsp;&nbsp;|&nbsp;&nbsp;</span> */}
              </Typography>
              {/* <Typography fontSize={contentFontSize}>
                <span className="sec-text">{locale?.general?.representative?.labels?.planProgress}:</span> 0%
              </Typography> */}
            </Box>
          </Box>
        )}
    </Box>
  );
};

export default ClientCard;
