import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CurrentScreen, RepData, setCurrentScreen, User } from "../../utils/redux/reducer/authentication-slice";
import { Box, IconButton, Typography } from "@mui/material";
import "./styles.css";
import { themeColors } from "../../utils/constants/themeColors";
import AssistanceIcon from "../../assets/svg/assistance-icon";
import { sendEvent } from "../../utils/helperFunctions/sendEvent";
import LogoutIcon from "@mui/icons-material/Logout";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import SocialDistanceOutlinedIcon from "@mui/icons-material/SocialDistanceOutlined";
import { screenNames } from "../../utils/constants/screenNames";
import { Locale } from "../../utils/redux";
import { eventNames } from "../../utils/constants/events";
interface UserHeaderProps {}

interface IconButtonProps {
  clickFunction: () => void;
  ChildrenComp: any;
  size: number;
  tooltip: string;
}

const CustomIconButton = ({
  ChildrenComp,
  clickFunction,
  size,
  tooltip,
}: IconButtonProps) => {
  return (
    <Box data-tooltip-id="custom-tooltip" data-tooltip-content={tooltip}>
      <IconButton
        onClick={clickFunction}
        sx={{
          height: "39px",
          width: "39px",
          background: "#F7EAE1",
          boxShadow: "0 2px 6px rgba(0,0,0,.35)",
          border: "1px solid white",
          borderRadius: "5px",
          color: "#F36633",
          "& .MuiSvgIcon-root": { fill: "#F36633" },
          "&:hover": {
            background: "#F36633",
            "& .MuiSvgIcon-root": { fill: "#F7EAE1" },
          },
        }}
      >
        <ChildrenComp sx={{ fontSize: size }} />
      </IconButton>
    </Box>
  );
};

const UserHeader: FC<UserHeaderProps> = () => {
  const dispatch = useDispatch();
  const user = useSelector(User);
  const repData = useSelector(RepData);
  const locale = useSelector(Locale);
  const currentScreen = useSelector(CurrentScreen);
  const handleLogOut = () => {
    sendEvent("logout", {});
  };

  return (
    <Box>
      <Box className="user-header-container">
        <Box className="user-data-container" color={themeColors.secondaryType2}>
          <Box>
            <Typography fontWeight={500}>{user?.name || repData?.name}</Typography>
            <Typography style={{ color: themeColors.secondaryType2 }}>
              {user?.email || repData?.email}
            </Typography>
          </Box>
        </Box>
        <Box
          display={"flex"}
          alignItems={"center"}
          gap={"18px"}
          flexWrap={"wrap"}
        >
          {currentScreen!==screenNames.clientSelection && repData?.isRep && <CustomIconButton
            tooltip={locale?.general?.userHeader?.tooltip?.changeUser}
            clickFunction={() => {
              dispatch(setCurrentScreen(screenNames.clientSelection));
              sendEvent('get-client-list',{})
            }}
            size={27}
            ChildrenComp={SocialDistanceOutlinedIcon}
          />}
          <CustomIconButton
            tooltip={locale?.general?.userHeader?.tooltip?.profile}
            clickFunction={() => {
              sendEvent(eventNames.profileDetails,{});
              dispatch(setCurrentScreen(screenNames.userProfile));
            }}
            size={27}
            ChildrenComp={ManageAccountsIcon}
          />
          <CustomIconButton
            tooltip={locale?.general?.userHeader?.tooltip?.help}
            clickFunction={() => {
              sendEvent("help", {});
            }}
            size={27}
            ChildrenComp={AssistanceIcon}
          />
          <CustomIconButton
            tooltip={locale?.general?.userHeader?.tooltip?.logout}
            clickFunction={handleLogOut}
            size={26}
            ChildrenComp={LogoutIcon}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default UserHeader;
