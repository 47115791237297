/* eslint-disable array-callback-return */
import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ClientCard from "./ClientCard";
import TextField from "@mui/material/TextField";
import CustomButton from "../../../components/CustomButton/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import {
  Loc,
  Locale,
  RepData,
  setLoading,
  setUser,
} from "../../../utils/redux";
import axiosInstance from "../../../utils/axios";
import { URLS } from "../../../utils/constants/urls";
import { PharmacyUserResponse } from "../../../utils/types";

const iconRow = {
  r1: "46px",
  r2: "46px",
  r3: "34px",
  r4: "42px",
  r5: "32px",
  r6: "38px",
  r7: "28px",
};

const ClientSelection = () => {
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [filterValue, setFilterValue] = useState("");
  const dispatch = useDispatch();
  const loc = useSelector(Loc);
  const [allClientList, setAllClientList] = useState<
    { customerType: string; customerData: PharmacyUserResponse[] }[]
  >([]);
  const [selectedCustomerType, setSelectedCustomerType] = useState("");
  const [clientList, setClientList] = useState<
    { customerType: string; customerData: PharmacyUserResponse[] }[]
  >([]);
  const repData = useSelector(RepData);
  const locale = useSelector(Locale);
  const getClientList = async () => {
    try {
      dispatch(setLoading(true));
      const {
        data,
      }: {
        data: {
          userId: string;
          name: string;
          phoneNumber: string;
          email: string;
          clientCode: string[];
          pharmacyUserResponsesMapping: PharmacyUserResponse[];
        };
      } = await axiosInstance.get(URLS.getClientList(repData.phone, loc));
      if (
        data &&
        data.pharmacyUserResponsesMapping &&
        data.pharmacyUserResponsesMapping.length > 0
      ) {
        const updatedPharmacyUserResponsesMapping = data.pharmacyUserResponsesMapping.map(item => {
          if(!item.customerType || item.customerType===""){
            return {
              ...item,
              customerType: "Customer Type NA"
            }
          }else{
            return {...item, customerType: item?.customerType?.toUpperCase()}
          }
        })
        const uniqueCustomerTypes = Array.from(
          new Set(
            updatedPharmacyUserResponsesMapping.map((item) => item.customerType)
          )
        );        
        const groupedCustomerData = [];
        for (let customerType of uniqueCustomerTypes) {
          const filtredCustomerData = updatedPharmacyUserResponsesMapping.filter(
            (item) => item.customerType === customerType
          );
          if (filtredCustomerData.length > 0) {
            groupedCustomerData.push({
              customerType,
              customerData: filtredCustomerData,
            });
          }
        }
        setClientList(groupedCustomerData);
        setAllClientList(groupedCustomerData);
      }
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      console.log(error);
    }
  };

  useEffect(() => {
    dispatch(setUser({}));
    getClientList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClientFilter = (value: string) => {
    setFilterValue(value);
    if (value === "") {
      setSelectedCustomerType("");
      setSelectedIndex(-1);
      setClientList(allClientList);
    } else {
      // eslint-disable-next-line array-callback-return
      const filtredData: {
        customerType: string;
        customerData: PharmacyUserResponse[];
      }[] = [];
      for (let clientData of allClientList) {
        const filtredClientData = clientData.customerData.filter((client) => {
          const combinedString =
            client.firstName +
            client.lastName +
            client.email +
            client.phoneNumber +
            client.user_id +
            client.clientCode +
            client.registrationGroupName;
          let isExist = combinedString
            .toLowerCase()
            .includes(value.toLowerCase());

          if (isExist) {
            return client;
          }
        });

        if (filtredClientData.length > 0) {
          filtredData.push({
            customerType: clientData.customerType,
            customerData: filtredClientData,
          });
        }
      }
      setClientList(filtredData);
      setSelectedCustomerType("");
      setSelectedIndex(-1);
    }
  };

  return (
    <Box>
      <Box
        className="pl20 pr20 pt20"
        sx={{ position: "sticky", top: 0 }}
        bgcolor={"#f0efed"}
      >
        <Typography color="primary.main" fontSize={14} fontWeight={500}>
          {locale?.general?.representative?.labels?.customerList}
        </Typography>
        <Box className="flex g10 mt5 mb10 ai-c">
          <TextField
            value={filterValue}
            onChange={(e) => handleClientFilter(e.target.value)}
            placeholder={locale?.general?.representative?.labels?.filterClient}
            className="fullWidth"
            sx={{
              "& .MuiInputBase-root": {
                borderRadius: "3px",
                background: "white",
                height: "25px",
                padding: "0 0px",
                "& fieldset": {
                  borderColor: "gray",
                },
              },
              "& .MuiOutlinedInput-input": {
                padding: "0 12px",
              },
              "& .MuiInputBase-input::placeholder": {
                fontSize: "13px",
              },
            }}
          />
          <CustomButton
            onClick={() => {
              setFilterValue("");
              setClientList(allClientList);
            }}
            align="center"
            width="110px"
            sx={{
              height: 23,
            }}
          >
            <Typography fontSize={12}>
              {locale?.general?.representative?.buttons?.clear}
            </Typography>
          </CustomButton>
        </Box>
        <Box className="flex mb5">
          <Box className="f1" />
          <Box className="flex g5 ai-c">
            <Typography color="primary.main" fontSize={12} width={iconRow.r1}>
              {locale?.general?.representative?.labels?.consent}
            </Typography>
            <Typography
              color="primary.main"
              textAlign={"center"}
              fontSize={12}
              sx={{
                display: "inline-block",
                lineHeight: 1.2,
                width: iconRow.r2,
              }}
            >
              {locale?.general?.representative?.labels?.validAccounts}
            </Typography>
            <Typography color="primary.main" fontSize={12} width={iconRow.r3}>
              {locale?.general?.representative?.labels?.offers}
            </Typography>
            <Typography color="primary.main" fontSize={12} width={iconRow.r4}>
              {locale?.general?.representative?.labels?.activity}
            </Typography>
            <Typography color="primary.main" fontSize={12} width={iconRow.r5}>
              {locale?.general?.representative?.labels?.order}
            </Typography>
            <Typography color="primary.main" fontSize={12} width={iconRow.r6}>
              {locale?.general?.representative?.labels?.access}
            </Typography>
            <Box width={iconRow.r7} />
          </Box>
        </Box>
      </Box>
      <Box className="flex fdc auto-o fullHeight pl20 pr20 pb20">
        {clientList.map((clientData) => {
          return (
            <Box key={clientData.customerType} mb={"5px"}>
              <Typography mb={"5px"} fontWeight={"bold"}>
                {clientData.customerType}
              </Typography>
              {clientData.customerData.map((client, index) => {
                return (
                  <ClientCard
                    key={client.email + index}
                    client={client}
                    iconRow={iconRow}
                    setSelectedIndex={setSelectedIndex}
                    indexValue={index}
                    selectedCustomerType={selectedCustomerType}
                    setSelectedCustomerType={setSelectedCustomerType}
                    selectedIndex={selectedIndex}
                  />
                );
              })}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default ClientSelection;
